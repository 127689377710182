import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppContext';
import { Field, FieldGroup, Fieldset, Label } from './catalyst/fieldset';
import { Input } from './catalyst/input';
import { Button } from './catalyst/button';
import { Textarea } from './catalyst/textarea';
import { Select } from './catalyst/select';
import { Divider } from './catalyst/divider';
import { useParams, useNavigate } from 'react-router-dom';
import MyDropdown from './MyDropdown';
import axios from 'axios';
import { loggingAPI } from 'api';

function SupportPage() {
	const { manList } = useContext(AppContext);
	const { urlMfgName, urlSN, urlMN, urlRQ } = useParams();
	const navigate = useNavigate();

	const [formValues, setFormValues] = useState({
		manName: '',
		typeOfUnit: '',
		modelNum: '',
		serialNum: '',
		partsNeeded: '',
		accountNum: '',
		contactName: '',
		cellNum: '',
		email: '',
		prefContact: '',
	});

	// State for whether the form is submitting
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Helper function to check if a value is empty or whitespace-only
	const isEmptyOrWhitespace = (value) => !value || value.trim().length === 0;

	// Initial setup of form values
	const [urlMfgNameProcessed, setUrlMfgNameProcessed] = useState('');
	const [urlSNProcessed, setUrlSNProcessed] = useState('');
	const [urlMNProcessed, setUrlMNProcessed] = useState('');
	const [urlRQProcessed, setUrlRQProcessed] = useState('');
	useEffect(() => {
		console.log("SETTING UP FORM")
		try {
			setUrlMfgNameProcessed(decodeURIComponent(urlMfgName.split("=")[1]));
		} catch {
			try {
				setUrlMfgNameProcessed(urlMfgName.split("=")[1]);
			} catch {
				setUrlMfgNameProcessed('');
			}
		}

		try {
			setUrlSNProcessed(decodeURIComponent(urlSN.split("=")[1]));
		} catch {
			try {
				setUrlSNProcessed(urlSN.split("=")[1]);
			} catch {
				setUrlSNProcessed('');
			}
		}

		try {
			setUrlMNProcessed(decodeURIComponent(urlMN.split("=")[1]));
		} catch {
			try {
				setUrlMNProcessed(urlMN.split("=")[1]);
			} catch {
				setUrlMNProcessed('');
			}
		}

		try {
			setUrlRQProcessed(decodeURIComponent(urlRQ.split("=")[1]));
		} catch {
			try {
				setUrlRQProcessed(urlRQ.split("=")[1]);
			} catch {
				setUrlRQProcessed('');
			}
		}

		console.log([urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed]);

		// Sets form values to the URL parameters if they exist
		if (urlMfgNameProcessed || urlSNProcessed || urlMNProcessed || urlRQProcessed) {
			setFormValues(prevValues => ({
				...prevValues,
				manName: ((manList.map(item => item.value)).includes(urlMfgNameProcessed) ? urlMfgNameProcessed : ''),
				modelNum: urlMNProcessed ? urlMNProcessed.toUpperCase() : '',
				serialNum: urlSNProcessed ? urlSNProcessed.toUpperCase() : '',
				partsNeeded: urlRQProcessed ? urlRQProcessed : '',
			}));
		}
	}, [urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed, setUrlMNProcessed, setUrlMfgNameProcessed, setUrlRQProcessed, setUrlSNProcessed, urlMfgName, urlSN, urlMN, urlRQ]);


	// Error Checking
	const [errors, setErrors] = useState({});
	const [validationError, setValidationError] = useState(null);

	// API call to validate the ticket
	const validateTicket = async (ticketData) => {
		try {
			const response = await loggingAPI.post('/api/validate-ticket', ticketData);
			return response.data.status === "success";
		} catch (error) {
			console.error("Error validating ticket:", error);
			return false;
		}
	};

	const handleChange = (event) => {
		const { name, value, type } = event.target;

		// Doesn't need cursor logic if it's a select field
		if (type === "select-one") {
			setFormValues({
				...formValues,
				[name]: value,
			});
		} else {

			const input = event.target;

			// Store current cursor position in the text field
			let { selectionStart, selectionEnd } = input;
		
			// Error handling
			const tempErrors = { ...errors };
			if (["accountNum", "cellNum"].includes(name)) {
				// Shows this error if person tried putting in a non-digit character when the field is less than maxLength characters
				// (doesn't show error if the field is maxed out)
				const maxLength = (name === "accountNum" ? 7 : 10);
			
				// Validate for non-digit characters within the specified max length
				if (/\D/.test(value) && value.length <= maxLength) {
					tempErrors[name] = <span className="error-text">Must contain only numbers</span>;
				} else {
					delete tempErrors[name];
				}
			
				setErrors(tempErrors);
			}
		
			// Save the old value
			let processedValue = value;
		
			// Process input based on field name
			if (["accountNum", "cellNum"].includes(name)) {
				const maxLength = (name === "accountNum" ? 7 : 10);

				// Remove all non-digit characters
				const digitsOnly = value.replace(/\D/g, '');
		
				// Prevent insertion of anything if the field already has maxLength digits
				if (digitsOnly.length > maxLength) {
					// Preserve cursor position after updating state
					requestAnimationFrame(() => {
						input.setSelectionRange(selectionStart-1, selectionEnd-1);
					});

					return; // Exit the handler to stop further updates
				} else {
					// Calculate new cursor position to stay in the same spot after removing invalid characters
					const removedCharactersCount = value.length - digitsOnly.length;
					const effectiveCursorPosition = Math.max(
						selectionStart - removedCharactersCount,
						0
					);
			
					processedValue = digitsOnly.slice(0, maxLength);
			
					// Update cursor positions
					selectionStart = effectiveCursorPosition;
					selectionEnd = effectiveCursorPosition;
				}
			} else if (name === "modelNum" || name === "serialNum") {
				processedValue = value.toUpperCase();
			}
		
			// Update form values
			setFormValues({
				...formValues,
				[name]: processedValue,
			});
		
			// Preserve cursor position after updating state
			requestAnimationFrame(() => {
				input.setSelectionRange(selectionStart, selectionEnd);
			});
		}
	};
	
	

	// SALESFORCE HANDLE SUBMIT ================================================================================================================================================
	// ========================================================================================================================================================================

	// Validates ticket, then writes ticket IF valid. Otherwise, displays error message.
	const handleSubmit = async (event) => {
		event.preventDefault();
		setValidationError(null);
		console.log("Calling validation() function...");
		if (validate()) {
			console.log("Validation function passed");
			setIsSubmitting(true);
			const isValid = await validateTicket(formValues);
			if (!isValid) {
				setValidationError(<span className="error-text">Try a different account number.</span>);
				setIsSubmitting(false);
			} else {
				console.log("Ticket is valid. Calling /write-ticket endpoint...");
	
				try {
					console.log("IsSubmitting", isSubmitting);
					const response = await loggingAPI.post('/api/create-ticket', formValues, {
						headers: {
							"Content-Type": "application/json",
						},
					});
	
					const result = response.data;
	
					if (response.status >= 200 && response.status <= 299 && result.status === "success") {
						// Display confirmation page upon success
						const ticketID = response.data.ticket_id;
						navigate('/submitted', { state: { formValues, ticketID } });
					} else {
						setValidationError(<span className="error-text">{result.message || "Failed to create ticket. Please try again."}</span>);
					}
				} catch (error) {
					setValidationError(<span className="error-text">An error occurred while creating the ticket. Please try again.</span>);
					console.error("Error:", error);
				} finally {
					setIsSubmitting(false);
				}
			}
		} else {
			console.log("Validation failed.");
		}
	};
	
	// ========================================================================================================================================================================
	// ========================================================================================================================================================================
	

	// NEW: validation function for all fields (geneva)
	function validate() {
		let newErrors = {};

		// Manufacturer validation
		if (isEmptyOrWhitespace(formValues.manName)) {
			newErrors.manName = <span className="error-text">Manufacturer is required</span>;
		}
	
		// Type of Unit validation
		if (isEmptyOrWhitespace(formValues.typeOfUnit)) {
			newErrors.typeOfUnit = <span className="error-text">Type of Unit is required</span>;
		}
	
		// Model Number validation
		if (isEmptyOrWhitespace(formValues.modelNum)) {
			newErrors.modelNum = <span className="error-text">Model Number is required</span>;
		} else if (formValues.modelNum.length > 20) {
			newErrors.modelNum = <span className="error-text">Model Number must not exceed 20 characters</span>;
		}
		// Parts Needed validation
		if (isEmptyOrWhitespace(formValues.partsNeeded)) {
			newErrors.partsNeeded = <span className="error-text">Parts Needed is required</span>;
		}
	
		// Account Number validation
		// If accountNum does have something in it, it must be in the form of a 7-digit number
		if (isEmptyOrWhitespace(formValues.accountNum)) {
			newErrors.accountNum = <span className="error-text">Account Number is required</span>;
		} else {
			if (formValues.accountNum.length !== 7) {
				newErrors.accountNum = <span className="error-text">Must be 7 digits long.</span>;
			} else if (!/^\d+$/.test(formValues.accountNum)) {
				newErrors.accountNum = <span className="error-text">Must contain only numbers</span>;
			}
		}
	
		// Contact Name validation
		if (isEmptyOrWhitespace(formValues.contactName)) {
			newErrors.contactName = <span className="error-text">Business or personal name is required</span>;
		}
	
		// Cell Phone Number validation
		if (isEmptyOrWhitespace(formValues.cellNum)) {
			newErrors.cellNum = <span className="error-text">Cell Phone Number is required</span>;
		} else if (!/^\d{10}$/.test(formValues.cellNum)) {
			newErrors.cellNum = <span className="error-text">Must be exactly 10 digits and contain only numbers</span>;
		}
	
		// Email validation
		if (isEmptyOrWhitespace(formValues.email)) {
			newErrors.email = <span className="error-text">Email Address is required</span>;
		} else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
			newErrors.email = <span className="error-text">Please enter a valid email address</span>;
		}
	
		// Preferred Contact validation
		if (isEmptyOrWhitespace(formValues.prefContact)) {
			newErrors.prefContact = <span className="error-text">Preferred Contact Option is required</span>;
		}
	
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	}
	
	

	return (
		<div className="min-h-screen flex flex-col items-center w-full">
			<div className='w-11/12'>
				<h1 className='text-3xl font-bold mb-4 dark:text-white'>Support Page</h1>
			</div>
			<Divider />
			<div className='w-96'>
				<form onSubmit={handleSubmit}>
					<Fieldset>
						<FieldGroup>
							<Field>
								<Label>Manufacturer *</Label>
								<MyDropdown
									manName={formValues.manName}
									setManName={(currentValue) => setFormValues({ ...formValues, manName: currentValue })}
								/>
								{errors.manName && <span className="text-red-500">{errors.manName}</span>}
							</Field>
							<Field>
								<Label>Type of Unit *</Label>
								<Input name="typeOfUnit" value={formValues.typeOfUnit} onChange={handleChange} />
								{errors.typeOfUnit && <span className="text-red-500">{errors.typeOfUnit}</span>}
							</Field>
							<Field>
								<Label>Model Number *</Label>
								<Input name="modelNum" value={formValues.modelNum} onChange={handleChange} />
								{errors.modelNum && <span className="text-red-500">{errors.modelNum}</span>}
							</Field>
							<Field>
								<Label>Serial Number</Label>
								<Input name="serialNum" value={formValues.serialNum} onChange={handleChange} />
								{errors.serialNum && <span className="text-red-500">{errors.serialNum}</span>}
							</Field>
							<Field>
								<Label>Parts Needed *</Label>
								<Textarea name="partsNeeded" value={formValues.partsNeeded} onChange={handleChange} />
								{errors.partsNeeded && <span className="text-red-500">{errors.partsNeeded}</span>}
							</Field>
							<Field>
								<Label>Account Number *</Label>
								<Input name="accountNum" value={formValues.accountNum} onChange={handleChange} />
								{errors.accountNum && <span className="text-red-500">{errors.accountNum}</span>}
							</Field>
							<Field>
								<Label>Contact Name *</Label>
								<Input name="contactName" value={formValues.contactName} onChange={handleChange} />
								{errors.contactName && <span className="text-red-500">{errors.contactName}</span>}
							</Field>
							<Field>
								<Label>Cell Phone Number *</Label>
								<Input name="cellNum" value={formValues.cellNum} onChange={handleChange} />
								{errors.cellNum && <span className="text-red-500">{errors.cellNum}</span>}
							</Field>
							<Field>
								<Label>Email Address *</Label>
								<Input name="email" value={formValues.email} onChange={handleChange} />
								{errors.email && <span className="text-red-500">{errors.email}</span>}
							</Field>
							<Field>
								<Label>Preferred Contact Option *</Label>
								<Select name="prefContact" value={formValues.prefContact} onChange={handleChange}>
									<option value="">Preferred Contact Option</option>
									<option value="Email Only">Email Only</option>
									<option value="Email & Phone">Email & Phone</option>
									<option value="Phone Only">Phone Only</option>
								</Select>
								{errors.prefContact && <span className="text-red-500">{errors.prefContact}</span>}
							</Field>
						</FieldGroup>
					</Fieldset>
					<Button type='submit' className='w-full mt-10 h-full'>{isSubmitting ? "Submitting, please wait..." : "Submit"}</Button>
					{validationError && <div className="text-red-500 mt-2">{validationError}</div>}
					{(errors && Object.keys(errors).length > 0) && <div className="error-text text-red-500 mt-2">Please correct the indicated fields</div>}
				</form>
			</div>
		</div>
	);
}

export default SupportPage;
