import React from 'react'
import { Card, CardContent } from "../ui/card"
import { ExternalLink } from 'lucide-react'

import { TableRow } from '../catalyst/table';

export default function Component({
	tableRowNum,	//the row number in the table (aka key)
	ptPartName,	 	//part name by PartsTown (if repPartName has a name, this is outdated) (contains both manufacturer code + Parts Town's mfg code)
	mfgPartName,	//part name by manufacturer (contains only the part number without the mfg prefix)
}) {
  return (
	<TableRow key={tableRowNum}>
		<Card className="w-fullmax-w-md bg-muted/50 border-muted-foreground/50 mb-2">
			<CardContent className="p-6">
				<div className="flex flex-col items-center text-center">
					<p className="text-muted-foreground">
						No parts on record for that model, but please visit
					</p>
					<a
						href={ptPartName ? ptPartName : mfgPartName}
						target="_blank"
						rel="noopener noreferrer"
						className="inline-flex items-center gap-1 text-primary hover:underline"
					>
						{(ptPartName ? ptPartName : mfgPartName).slice(0, 43)}
						<ExternalLink className="h-4 w-4" />
					</a>
				</div>
			</CardContent>
		</Card>
	</TableRow>
  )
}